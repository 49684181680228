import React, {Component} from 'react'
import {Container, Row, Col} from 'reactstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ContactImage from "../components/ContactImage";

class SuccessMail extends Component {

    render() {
        return (
            <Layout>
                <SEO googleTitle="Medoboard - optimiert Ihre Ordination"
                     googleDescription="Medoboard organisiert Ihre Ordination mit intelligenten Funktionalitäten und optimiert Ihre Praxis."
                     fbUrl="https://medoboard.com/kontakt"
                     fbTitle="Medoboard - optimiert Ihre Ordination"
                     fbDescription="Medoboard organisiert Ihre Ordination mit intelligenten Funktionalitäten."
                     keywords={[`medoboard`, `Ordinationssoftware`, `intelligent`, `einfach`, `Patientenakte`, `Arztsoftware`]}/>
                <Container>
                    <Row className="carousel">
                        <Col md="12">
                            <ContactImage/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h2 className="featurette-heading">Kontakt</h2>
                            <h4 className="text-muted">Vielen Dank für Ihre Anfrage</h4>
                            <hr className="featurette-divider"/>
                            <p>
                                Wir bemühen uns, ihr Anliegen so schnell wie möglich zu beantworten!
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Layout>
        )
    }
}

export default SuccessMail;